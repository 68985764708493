import MuiStack, { type StackProps as MuiStackProps } from '@mui/material/Stack';
import { forwardRef } from 'react';
export interface StackProps extends MuiStackProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Stack = forwardRef<HTMLDivElement, StackProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiStack ref={ref} {...rest}>
      {children}
    </MuiStack>;
});