'use client';

import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
export const AuthHeaderClient = ({
  id
}: {
  id: string;
}) => <Typography variant="h1" fontWeight={400} mb={2} fontSize="1.9rem" textAlign="center" data-test-id="auth-header" data-sentry-element="Typography" data-sentry-component="AuthHeaderClient" data-sentry-source-file="AuthHeaderClient.tsx">
    <FormattedMessage id={id} data-sentry-element="FormattedMessage" data-sentry-source-file="AuthHeaderClient.tsx" />
  </Typography>;