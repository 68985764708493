export const formatQueryParams = (
  params: Record<string, string | number | boolean | undefined | null> | URLSearchParams,
  ignore: string[] = [],
): string => {
  let entries: [string, string | number | boolean | undefined | null][];

  if (params instanceof URLSearchParams) {
    entries = Array.from(params.entries()).map(([key, value]) => [key, value] as [string, string]);
  } else {
    entries = Object.entries(params);
  }

  const queryString = entries
    .filter(([key, value]) => value !== undefined && value !== null && !ignore.includes(key))
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};
